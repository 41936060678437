export const startWithADiamond = "start-with-a-diamond"
export const selectADiamondForASetting = "select-a-diamond-for-a-setting"

export const noRedirect = "no-redirect"
export const shopRedirect = "shop-redirect"

export const initProducts = [
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
    {
        "id": "37",
        "labgrown": "false",
        "shape": "PEAR",
        "carats": "1.02",
        "price": "6757.99",
        "cut": "ID",
        "color": "D",
        "clarity": "VVS1",
        "length": "9.5",
        "width": "5.73",
        "lwRatio": "1.66",
        "fluorescence": "FNT",
        "polish": "EX",
        "symmetry": "GD",
        "table": "61.0",
        "depth": "50.2",
        "origin": "",
        "report": "GIA",
        "reportNo": "5182792146",
        "certificate": "https://nivoda-certificates.s3.eu-west-2.amazonaws.com/pdf/5182792146.pdf",
        "video": "https://loupe360.com/diamond/5182792146/video/500/500?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "image": "https://nivoda-images.nivodaapi.net/aHR0cHM6Ly92aXRyYWFnLnNpcnYuY29tL1ZpZGVvcy8zMTg2My5tcDQtZg==/0.jpg?d_id=4a61ed6a-6965-4f1d-b893-d19f1ca3d52e&c_id=ef5606e4-9e26-4019-9024-ddd6e2105c0e&f_id=41f63abf-4872-4e3f-91b3-22e0c4436aa4&type=csv",
        "stocknumber": "703FB4D62",
        "height": "2.88",
        "girdle": "VTK - ETK",
        "culet": "",
        "loading": "true"
    },
]