import React, { useState, useEffect, MouseEventHandler } from 'react';

import WorkflowDiagram from "./components/Workflowdiagram/WorkflowDiagramParent";
import DiamondSelector from "./components/DiamondSelector";
import Title from "./components/Title";
import Breadcrumb from "./components/Breadcrumb";
import './styles/app_styles.css';
import { startWithADiamond, selectADiamondForASetting, noRedirect, shopRedirect } from './config/config'

/* (function () {
    const originalPushState = window.history.pushState;

    // Override pushState
    window.history.pushState = function (state, title, url) {
        originalPushState.call(window.history, state, title, url);
        // const result = originalPushState.apply(this, arguments); 
        // Dispatch a custom event
        window.dispatchEvent(new CustomEvent("hashchange2", { detail: { state, title, url } }));
        //  return result; 
    };
})(); */

function App() {
  const [show, setShow] = useState<boolean>(false);
  const [viewPort, setViewport] = useState<string>("desktop");
  const [applicationState, setApplicationState] = useState<string>("");
  const [selectedDiamondShape, setSelectedDiamondShape] = useState<string>("");

  //HANDLE RESIZE
  useEffect(() => {

    function handleResize() {

      let width = window.innerWidth

      if (width < 350)
        //remove compare
        setViewport("tinyMobile")
      else if (width < 500)
        //remove compare
        setViewport("mobile")

      else if (width < 1020)
        //add filter popup
        setViewport("tablet")
      else
        setViewport("desktop")
    }

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //ONLOAD
  useEffect(() => {

    const temphash = window.location.hash.substring(1)
    const hash = temphash.split("_")[0]


    if (temphash.split("_").length > 1) {
      const diamondHash = temphash.split("_")[1]
      setSelectedDiamondShape(diamondHash.toUpperCase())
    }


    if (hash === `${startWithADiamond}`) {
      setApplicationState(startWithADiamond)
      showApp(true)
    }
    else if (hash.includes(`${selectADiamondForASetting}`)) {
      setApplicationState(selectADiamondForASetting)
      showApp(true)
    }
    else
      window.addEventListener('message', handleMessage);

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  const handleMessage = (event: { origin: string; data: { action: string; }; }) => {
    console.log(event)
    if (event.origin !== 'https://everrings.com') return;

    const action = event.data.action

    console.log("action", action)

    if (!action || action === "jsException") {

    } else {
      setApplicationState(action.split("_")[0])
      //TODOLOWPRIO MAKE A FUNCTION THAT GETS SELECTABLE DIAMONDSHAPE FROM SESSIONSTORAGE AND IF IN SESSIONSTORAGE IS NOT CORRECT THEN MAKES API CALL
      if (action.split("_")[1])
        setSelectedDiamondShape(action.split("_")[1].toUpperCase())
      showApp(true)
    }
  }

  const onPopState = () => {
    console.log("hashchange")

    const temphash = window.location.hash.substring(1)
    const hash = temphash.split("_")[0]
    if (hash.split("_")[1])
      setSelectedDiamondShape(hash.split("_")[1].toUpperCase())

    if (window.location.hash === "" || window.location.hash === "#")
      showApp(false)
    else if (hash === "select-a-diamond-for-a-setting" || hash === "start-with-a-diamond") {
      if (sessionStorage.wasDiamondFirstSet)
        sessionStorage.removeItem("wasDiamondFirstSet")
      showApp(true)
      setApplicationState(hash)
    }

    else if (hash.includes("diamond=")) {
      showApp(false)
    }
  }

  const showApp = (value: boolean) => {
    setShow(value);
    const footer: HTMLElement | null = document.querySelector(".elementor-location-footer")
    const main: HTMLElement | null = document.querySelector(".elementor-location-single")
    const main2: HTMLElement | null = document.querySelector(".site-main")
    const archive: HTMLElement | null = document.querySelector(".elementor-location-archive")

    if (value) {
      if (main)
        main.style.display = "none"
      if (footer)
        footer.style.display = "none"
      if (main2)
        main2.style.display = "none"
      if (archive)
        archive.style.display = "none"
    } else {
      if (main)
        main.style.display = "block"
      if (footer)
        footer.style.display = "block"
      if (main2)
        main2.style.display = "block"
      if (archive)
        archive.style.display = "block"
    }

  }

  const onClickOnClose = (event: React.MouseEvent<HTMLElement>) => {

    console.log(event)

    /* sessionStorage.setItem("wasDiamondFirstSet", "true") */

    if (sessionStorage.wasDiamondFirstSet)
      sessionStorage.removeItem("wasDiamondFirstSet")

    switch (event.currentTarget.dataset.info) {
      case shopRedirect:
        //TODO MAYBE IMPLEMENT SO WHEN CLIENT IS ALREADY ON SHOP THAT ONLY history.pushState is getting called
        window.location.href = "https://everrings.com/shop/"
        break
      case noRedirect:
        NoRedirect()
        break
      default:
        console.error("ERROR REDIRECTING")
        break
    }

    /*  window.location.reload() */
  }

  function NoRedirect() {
    showApp(false)
    /* window.history.pushState(null, '', "#") */
    window.history.back()
  }

  useEffect(() => {
    function handleEsc(event: { key: string; }) {
      if (event.key === 'Escape') {
        NoRedirect()
      }
    }

    window.addEventListener('keydown', handleEsc);
    // Clean up the listener on unmount to prevent memory leaks
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className="App" style={{
      position: "absolute", top: 0, background: "white", zIndex: 99999, width: "100%", left: 0
    }}>

      {show &&
        <div>
          <Breadcrumb />

          <Title />

          <WorkflowDiagram
            isSettingSet={true}
            callback={onClickOnClose}
            applicationState={applicationState}
          />

          <DiamondSelector
            callback={() => showApp(false)}
            viewPort={viewPort}
            applicationState={applicationState}
            selectedDiamondShape={selectedDiamondShape}
          />

          <button data-info={noRedirect} onClick={onClickOnClose} style={{ position: "absolute", top: 10, right: 10, zIndex: 999 }} className='btn-close'>
            <span className="icon-cross"></span>
            <span className="visually-hidden">Close</span>
          </button>
        </div>}
    </div>

  );
}

export default App;
