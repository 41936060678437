import React, { useState } from 'react';

type SvgButtonProps = {
    callback: React.Dispatch<React.SetStateAction<boolean>>;
    isGridView: boolean;
    name: string;
    children: React.ReactNode;
    forGrid: boolean;
};

const SvgButton: React.FC<SvgButtonProps> = ({ callback, isGridView, name, children, forGrid }) => {
    /* console.log("SvgButtonRender") */

    return (

        <div onClick={() => callback(forGrid ? true : false)} className={(forGrid ? isGridView : !isGridView) ? 'filter-type active' : 'filter-type'}>
            <span>
                <svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {children}
                </svg>
            </span>
            <span>{name}</span>
        </div>
    );
};

export default SvgButton