import React from 'react';

function Title() {

    return (
        <div id='title'>
            <div className='inner container'>
                <header>
                    <h1 style={{fontSize:20}}>
                            Design Your Own Diamond Engagement&nbsp;Ring
                    </h1>
                </header>


                <div className='paragraph-container'>
                    <p>Forever has a nice ring to it. It all starts by selecting a setting and your dream diamond to create your made-to-order design engagement ring.</p>
                </div>
            </div>

        </div>

    );
}

export default Title;