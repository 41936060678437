import React, { useState } from 'react';

const DiamondStep: React.FC<{}> = ({ }) => {

    return (
        <div className="inner-inner-inner">

            <header>
                <h2><span>Choose</span> Diamond</h2>
            </header>

        </div>
    );
};

export default DiamondStep