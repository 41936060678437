import React, { MouseEventHandler, useState } from 'react';

import DiamondStep from "./DiamondStep";
import SettingStep from "./SettingStep";
import { startWithADiamond } from '../../config/config'

type WorkflowDiagramProps = {
    isSettingSet: boolean,
    callback: MouseEventHandler<HTMLElement>,
    applicationState: string,
};

const WorkflowDiagram: React.FC<WorkflowDiagramProps> = ({ isSettingSet, callback, applicationState }) => {

    return (
        <div id='workflow-diagram' className='react container container1260 '>
            <div className="inner">
                <div className="inner-inner">
                    <div className="inner-inner-inner">
                        <div className="step step-heading">
                            <div className="inner">
                                <div className="inner-inner">
                                    <div className="inner-inner-inner">
                                        <header>
                                            <h2 className="nostyle-heading">Design Your Ring</h2>
                                        </header>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={applicationState === startWithADiamond ? "step active" : "step"}>
                            <div className="inner">
                                <div className="inner-inner">
                                    <div className="number">1</div>

                                    {applicationState === startWithADiamond ?
                                        <DiamondStep /> :
                                        <SettingStep
                                            applicationState={applicationState}
                                            callback={callback}
                                        />
                                    }

                                    <div className="picture">
                                        <img src="//css.brilliantearth.com/static/img/svg/setting.svg" alt="setting" />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={applicationState === startWithADiamond ? "step" : "step active"}>
                            <div className="inner">
                                <div className="inner-inner">
                                    <div className="number">2</div>

                                    {applicationState === startWithADiamond ?
                                        <SettingStep
                                            applicationState={applicationState}
                                            callback={callback}
                                        /> :
                                        <DiamondStep />
                                    }
                                    <div className="picture">
                                        <img src="//css.brilliantearth.com/static/img/svg/diamond.svg" alt="diamond" />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="step">
                            <div className="inner">
                                <div className="inner-inner">
                                    <div className="number">3</div>
                                    <div className="inner-inner-inner">

                                        <header>
                                            <h2>Complete Ring</h2>
                                        </header>

                                        <div className="action">Select Ring Size</div>
                                    </div>
                                    <div className="picture">
                                        <img src="//css.brilliantearth.com/static/img/svg/ring.svg" alt="ring" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default WorkflowDiagram