import React, { useState, useEffect, useRef } from 'react';
import '../styles/test.css';

import { startWithADiamond, initProducts } from '../config/config'

import Filter from './Filter';
import SvgButton from './SvgButton';
import DropDownFilter from './DropDownFilter';
import CompareViewSelector from './CompareViewSelector';
import DiamondGrid from './DiamondGrid';
import DiamondList from './DiamondList';

/* (function () {
    const originalPushState = window.history.pushState;

    // Override pushState
    window.history.pushState = function (state, title, url) {
        originalPushState.call(window.history, state, title, url);
        // const result = originalPushState.apply(this, arguments); 
        // Dispatch a custom event
        window.dispatchEvent(new CustomEvent("hashchange2", { detail: { state, title, url } }));
        //  return result; 
    };
})(); */

type DiamondSelectorProps = {
    callback: () => void,
    viewPort: string
    applicationState: string,
    selectedDiamondShape: string,
};

interface DiamondElement {
    ID: any,
    stock_id: any,
    shape: any,
    carats: any,
    selectedColor: any,
    selectedClarity: any,
    selectedCut: any,
    selectedPolish: any,
    selectedSymmetry: any,
    selectedFluorescence: any,
    length: any,
    width: any,
    selectedDepth: any,
    selectedTable: any, /* "%" */
    culet: any,
    report: any, /* IGI, GIA or HRD etc... */
    girdle: any,
    selectedPrice: any,
    video: any,
    image: any,
    origin: any,
    labgrown: any,

    /* stocknumber: string,
    gemstone: string,  ... everything you can get  
    height: string, */

    /*  certificate: string,  everything you can find  */
}



const DiamondSelector: React.FC<DiamondSelectorProps> = ({ callback, viewPort, applicationState, selectedDiamondShape }) => {
    /* console.log("diamondSelectorRender") */
    let isLoading = useRef(false)
    let sentinel = useRef(null)
    let diamondItemOffset = useRef(1)
    let fetchNumber = useRef(0)

    const [sortingByAsc, setSortingBy] = useState<[string, boolean]>(["popular", true])

    const dropDownItems = ["Most Popular", "Price (Low to High)", "Price (High to Low)", "Carat (Low to High)", "Carat (High to Low)", "Cut (Low to High)", "Cut (High to Low)", "Color (Low to High)", "Color (High to Low)", "Clarity (Low to High)", "Clarity (High to Low)"/* , "Shape (A to Z)", "Shape (Z to A)" */]

    /* Products */
    const [products, setProducts] = useState<any[]>(initProducts);
    const [compareProducts, setCompareProducts] = useState<any[]>([])

    const [isGridView, setGridView] = useState<boolean>(true)
    const [isCompareView, setCompareView] = useState<boolean>(false)

    const [diamondUrl, setDiamondUrl] = useState<string>("")

    /* const [diamondItemOffset, setDiamondItemOffset] = useState<number>(1) */

    console.log(products)

    useEffect(() => {
        if (diamondUrl !== "") {
            fetchNumber.current++
            startFetchDiamondItems(diamondUrl, fetchNumber.current)
        }

    }, [diamondUrl])

    useEffect(() => {
        if (products.length > 0)
            if (sentinel.current) {
                observer.observe(sentinel.current);
            }

        return () => {
            observer.disconnect();
        };
    }, [sentinel, products, isCompareView])

    const observer = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry.isIntersecting && !isLoading.current) {
            GetMoreItems();
        }
    }, {
        /*  root: document.querySelector(".diamond-search-hybrid"), */
        rootMargin: '0px 0px 8000px 0px',
        threshold: 0,
    });

    const startFetchDiamondItems = async (url: string, fetchNumber2: number) => {
        console.log("--firstFetchDiamonds")

        setProducts(initProducts)
        const items = await fetchDiamondItems(url)

        if (items && fetchNumber2 === fetchNumber.current)
            setProducts(items)

    };

    const fetchDiamondItems = async (url: string): Promise<any[] | undefined> => {
        try {
            if (url !== "") {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data: any[] | undefined = await response.json(); // Type the response     

                return data
            }
        } catch (err) {
            console.error(err instanceof Error ? err.message : 'An unknown error occurred');
            return []
        }
    };

    const dropDownFilterSelectHandler = (value: string) => {

        switch (value) {
            case dropDownItems[0]:
                setSortingBy(["popular", true])
                break
            case dropDownItems[1]:
                setSortingBy(["Price", true])
                break
            case dropDownItems[2]:
                setSortingBy(["Price", false])
                break
            case dropDownItems[3]:
                setSortingBy(["Carat", true])
                break
            case dropDownItems[4]:
                setSortingBy(["Carat", false])
                break
            case dropDownItems[5]:
                setSortingBy(["Cut", true])
                break
            case dropDownItems[6]:
                setSortingBy(["Cut", false])
                break
            case dropDownItems[7]:
                setSortingBy(["Color", true])
                break
            case dropDownItems[8]:
                setSortingBy(["Color", false])
                break
            case dropDownItems[9]:
                setSortingBy(["Clarity", true])
                break
            case dropDownItems[10]:
                setSortingBy(["Clarity", false])
                break
        }
    }

    const GetMoreItems = async () => {

        if (!isLoading.current) {
            isLoading.current = true
            if (diamondUrl !== "") {
                if (!isCompareView) {
                    const items = await fetchDiamondItems(diamondUrl + `&offset=${diamondItemOffset.current * 50}`)

                    setProducts(products.concat(items))
                }
            }
            diamondItemOffset.current++
            isLoading.current = false
        }
    }

    const toggleCategorySort = (category: string) => {
        if (sortingByAsc[0] === category)
            setSortingBy([category, !sortingByAsc[1]])
        else
            setSortingBy([category, false])
    }

    function roundToNearest50(value: number) {
        return Math.round(value / 50) * 50;
    }

    const selectDiamond = (diamond: any) => {

        console.log("diamond selected")

        diamond.price = roundToNearest50(diamond.price)

        sessionStorage.setItem("diamond", JSON.stringify(diamond))

        console.log(sessionStorage)

        if (applicationState === startWithADiamond) {

            sessionStorage.setItem("wasDiamondFirstSet", "true")

            console.log(window.location.pathname)
            console.log(window.location.pathname === "/shop/")
            if (window.location.pathname === "/shop/") {
                console.log("path is shop")
                window.location.hash = `#diamond=${diamond.id}`
                callback()
            }/*  else if (window.location.pathname.includes("/product/")) {
                window.location.hash = `#diamond=${diamond.id}`
                callback()
            } */
            else
                window.location.href = "https://everrings.com/shop/" + `#diamond=${diamond.id}`
        } else {
            window.history.pushState(null, '', `#diamond=${diamond.id}&ring-selected`)

            callback()
        }
        window.scrollTo(0, 0);
    }

    const toggleCompare = (event: any, id: string) => {

        if (compareProducts.includes(id.trim()))
            setCompareProducts(compareProducts.filter(item => item !== id.trim()))
        else
            setCompareProducts([...compareProducts, id.trim()])
    }

    const getShapeDiamond = (shape: string): string => {
        switch (shape.toLowerCase()) {
            case "round":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/round.png"
            case "oval":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/oval.png"
            case "cushion":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/cushion.png"
            case "emerald":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/emerald.png"
            case "elongated cushion":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/elongated_cushion.png"
            case "pear":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/pear.png"
            case "radiant":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/radiant.png"
            case "princess":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/princess.png"
            case "marquise":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/marquise.png"
            case "asscher":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/asscher.png"
            default:
                return "ERROR"
        }
    }

    /* TODO Add functions for Most Sparkle ... etc */

    return (
        <div id='diamond-search'>
            <div className='container container1260 ir317-diamond-search-results mt-30'>
                <div className='diamond-search-hybrid'>
                    <Filter
                        sortingByAsc={sortingByAsc}
                        setUrl={setDiamondUrl}
                        viewPort={viewPort}
                        selectedDiamondShape={selectedDiamondShape}
                    />

                    <div className='item-grid'>
                        <div className='item-grid-filter'>
                            <div className='inner'>
                                <div className='filter-type-wrapper'>

                                    <SvgButton
                                        callback={setGridView}
                                        isGridView={isGridView}
                                        name={"Grid"}
                                        forGrid={true}
                                    >
                                        <rect width="9" height="9" fill="#999999"></rect>
                                        <rect x="11" width="9" height="9" fill="#999999"></rect>
                                        <rect y="11" width="9" height="9" fill="#999999"></rect>
                                        <rect x="11" y="11" width="9" height="9" fill="#999999"></rect>
                                    </SvgButton>

                                    <SvgButton
                                        callback={setGridView}
                                        isGridView={isGridView}
                                        name={"List"}
                                        forGrid={false}
                                    >
                                        <rect width="20" height="5.33333" fill="#999999"></rect>
                                        <rect y="7.33325" width="20" height="5.33333" fill="#999999"></rect>
                                        <rect y="14.6667" width="20" height="5.33333" fill="#999999"></rect>
                                    </SvgButton>
                                </div>

                                <DropDownFilter
                                    dropDownFilterSelectHandler={dropDownFilterSelectHandler}
                                    dropDownItems={dropDownItems}
                                    viewPort={viewPort}
                                />

                            </div>

                            {viewPort === "desktop" && <CompareViewSelector
                                compareProducts={compareProducts}
                                isCompareView={isCompareView}
                                products={products.length > 0 ? products[0].loading ? [] :  products: []}
                                setCompareView={setCompareView}
                            />}

                        </div>


                        {
                            products.length > 0 ?
                                (isGridView ?
                                    <DiamondGrid
                                        compareProducts={compareProducts}
                                        isCompareView={isCompareView}
                                        products={products}
                                        selectDiamond={selectDiamond}
                                        toggleCompare={toggleCompare}
                                        viewPort={viewPort}
                                    />
                                    :
                                    <DiamondList
                                        compareProducts={compareProducts}
                                        getShapeDiamond={getShapeDiamond}
                                        selectDiamond={selectDiamond}
                                        isCompareView={isCompareView}
                                        products={products}
                                        toggleCaratSort={() => toggleCategorySort("carat")}
                                        toggleClaritySort={() => toggleCategorySort("clarity")}
                                        toggleColorSort={() => toggleCategorySort("color")}
                                        toggleCompare={toggleCompare}
                                        toggleCutSort={() => toggleCategorySort("cut")}
                                        togglePriceSort={() => toggleCategorySort("price")}
                                        sortingByAsc={sortingByAsc}
                                        viewPort={viewPort}
                                    />
                                )
                                :
                                (
                                    <div style={{ paddingTop: 24, paddingLeft: 12, paddingRight: 6, fontSize: 14 }}>
                                        Unfortunately, no diamonds match your selected criteria. Please contact us to speak to a diamond specialist to help you find the perfect diamond.
                                    </div>
                                )
                        }



                        <div style={{ width: 1, height: 1 }} ref={sentinel} id={"sentinel"}>

                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default DiamondSelector;