import React, { useState } from 'react';

type DiamondFilterItemProps = {
    shape: string;
    toggleDiamondShape: (value: String) => void;
    diamondShapes: string[];
    shapeUrl: string;
};

const DiamondFilterItem: React.FC<DiamondFilterItemProps> = ({ shape, toggleDiamondShape, diamondShapes, shapeUrl }) => {
    /* console.log("DiamondFilterItemRender") */
    return (

        <li >
            <a onClick={() => toggleDiamondShape(shape)} className={diamondShapes.includes(shape) ? "custom-control-a active" : "custom-control-a"}>
                <span className="filter-thumbnail">
                    <img className="mbm-multiply" width="40" src={shapeUrl} alt={shape} />
                </span>
                <span>{shape.charAt(0).toUpperCase() + shape.slice(1).toLowerCase()}</span>
            </a>
        </li>
    );
};

export default DiamondFilterItem