import React, { MouseEventHandler, useState } from 'react';

type SelectedFiltersProps = {
    diamondShapes: string[],
    carat: number[],
    price: number[],
    cut: string[],
    color: string[],
    clarity: string[],
    lWRatio: number[],
    fluorescence: string[],
    polish: string[],
    symmetry: string[],
    table: number[],
    depth: number[],
    report: string[],
    delivery: string,
    resetFunction: (event: any) => void,
};

const SelectedFilters: React.FC<SelectedFiltersProps> = ({ diamondShapes,
    carat,
    price,
    cut,
    color,
    clarity,
    lWRatio,
    fluorescence,
    polish,
    symmetry,
    table,
    depth,
    report,
    delivery,
    resetFunction }) => {

    let filterIsApplied = false

    const returnSingleItemMarkup = (array: string[], category: string) => {
        if (array.length > 0) {
            filterIsApplied = true
            return array.map((item, index) => (
                <div data-value={`${category} ${item}`} onClick={resetFunction} key={index} className="item reset-button">
                    <span className="item exposed-item">{item.substring(0,1).toUpperCase()+item.substring(1).toLowerCase()}<span className="x">✕</span></span>
                </div>
            ))
        }
    }

    const returnStringElementMarkup = (array: string[], category: string) => {
        if (array.length > 0) {
            filterIsApplied = true
            return <div data-value={category} onClick={resetFunction} className="item reset-button">
                <span className="item exposed-item">{`${array[0]} - ${array[array.length - 1]}`}<span className="x">✕</span></span>
            </div>
        }

    }

    const returnIntElementMarkup = (array: number[], category: string, unit?: string) => {
        if (array.length > 0) {
            filterIsApplied = true
            return <div data-value={category} onClick={resetFunction} className="item reset-button">
                <span className="item exposed-item">{`${array[0] / 100} - ${array[1] / 100} ${unit}`}<span className="x">✕</span></span>
            </div>
        }
    }

    const returnPriceElementMarkup = (array: number[], category: string) => {
        if (array.length > 0) {
            filterIsApplied = true
            return <div data-value={category} onClick={resetFunction} className="item reset-button">
                <span className="item exposed-item">{`${array[0].toLocaleString('en-US')} - ${array[1].toLocaleString('en-US')} €`}<span className="x">✕</span></span>
            </div>
        }
    }

    /* console.log("SelectedFilterRender") */

    return (

        <div >
            <div style={{ minHeight: 60 }} className="listing-selector-box listing-selector-box--diamond pb15 exposed_filter_div" >
                <div className="listing-selector-set">
                    {returnSingleItemMarkup(diamondShapes, "shape")}

                    {returnIntElementMarkup(carat, "carat", "Carat")}

                    {returnPriceElementMarkup(price, "price")}
                    {returnStringElementMarkup(cut, "cut")}
                    {returnStringElementMarkup(color, "color")}
                    {returnStringElementMarkup(clarity, "clarity")}

                    {returnIntElementMarkup(lWRatio, "lWRatio", "")}

                    {returnStringElementMarkup(fluorescence, "fluorescence")}
                    {returnStringElementMarkup(polish, "polish")}
                    {returnStringElementMarkup(symmetry, "symmetry")}

                    {returnIntElementMarkup(table, "table", "%")}
                    {returnIntElementMarkup(depth, "depth", "%")}

                    {returnSingleItemMarkup(report, "report")}
                    
                    {filterIsApplied &&
                        <div data-value={"reset-all"} onClick={resetFunction} id="reset_all_filters" className="item reset-button">Reset All<span className="x">✕</span></div>}

                </div>
            </div>
        </div>
    );
};

export default SelectedFilters