import React, { useState } from 'react';

type DropDownFilterProps = {
    dropDownItems: string[],
    dropDownFilterSelectHandler: (value: string) => void,
    viewPort: string
};

const DropDownFilter: React.FC<DropDownFilterProps> = ({ dropDownItems, dropDownFilterSelectHandler, viewPort }) => {
    /* console.log("DropdDownFilterRender") */
    const [selectedItem, setSelectedItem] = useState<string>("Most Popular");
    const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

    return (
        <div className='dropdown-filter'>
            <div onMouseEnter={() => setDropDownIsOpen(true)}
                onMouseLeave={() => setDropDownIsOpen(false)} style={{ position: "relative", display: "inline-block" }}>
                {/* Dropdown Button */}
                <button
                className='dropDownFilter'

                    style={{
                        padding: (viewPort === "tinyMobile" ? "0px" : ".7em 1em .7em"),
                        margin: (viewPort === "tinyMobile" ? "0 10px" : "0"),
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid #d9d9d9",
                        borderRadius: "0",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize:"14px",
                        height:40
                    }}
                >
                    {selectedItem ? `Sort by: ${selectedItem}` : "Select an option"}
                    <svg style={{
                        width: 10,
                        height: 10,
                        marginLeft: 5
                    }} xmlns="http://www.w3.org/2000/svg" width="122" height="70" viewBox="0 0 122 70" fill="none"><path d="M2.60562 3.13226C4.01417 1.72386 5.92448 0.932643 7.91636 0.932642C9.90824 0.932642 11.8185 1.72386 13.2271 3.13226L61.0246 50.9274L108.822 3.13225C110.233 1.73525 112.139 0.953729 114.125 0.958596C116.11 0.963463 118.013 1.75431 119.417 3.15821C120.821 4.56211 121.612 6.46483 121.617 8.45029C121.622 10.4358 120.84 12.3424 119.444 13.7533L66.3359 66.8588C64.9274 68.2673 63.017 69.0586 61.0251 69.0586C59.0331 69.0586 57.1228 68.2673 55.7142 66.8588L2.6066 13.7533C1.90908 13.0561 1.35573 12.2282 0.978167 11.3171C0.600605 10.4059 0.406224 9.42932 0.406115 8.44304C0.406006 7.45676 0.600173 6.48012 0.977534 5.56889C1.35489 4.65766 1.90825 3.82969 2.60562 3.13226Z" fill="#2C2C2C"></path></svg>
                </button>

                {/* Dropdown Menu */}
                {dropDownIsOpen && (
                    <ul
                        style={{
                            listStyle: "none",
                            margin: 0,
                            padding: 0,
                            backgroundColor: "#FFF",
                            border: "1px solid #CCC",
                            borderRadius: "5px",
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            zIndex: 1000,
                            width: "100%",
                        }}
                    >
                        {dropDownItems.map((item, index) => (
                            <li
                                key={index}
                                onClick={(el) => {
                                    setSelectedItem(item);
                                    dropDownFilterSelectHandler(item)
                                    setDropDownIsOpen(false);
                                }}
                                style={{
                                    padding: "10px",
                                    cursor: "pointer",
                                    borderBottom: "1px solid #EEE",
                                }}
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

        </div>
    );
};

export default DropDownFilter