import React, { useState, useEffect } from "react";
import "../styles/rangeslidercss.css";

type CheckBoxesProps = {
    reports: string[];
    signedArray: string[];
    setReport: (event: any) => void;
};

const CheckBoxes: React.FC<CheckBoxesProps> = ({ reports, signedArray, setReport }) => {
    /* console.log("CheckBoxesRender") */

    return (
        <div style={{ marginBottom: 20 }}>
            <div className="widget-filter__cont " id="Report">
                <div className="d-flex mb5">
                    <div className="flex-fill" id="js_report" style={{ display: "flex", gap: "12px", flexDirection: "column" }}>

                        {reports.map((report, index) => (
                            <div key={index} className=" " style={{
                                display: "flex", gap: 10, alignItems: "center"
                            }}> {/* checkbox-ty7-green */}
                                <input style={{ width: 16, height: 16, margin: 0 }} aria-label={'Report ' + report} type="checkbox" onChange={setReport} checked={signedArray.includes(report)} value={report} />
                                {/*  <label style={{ display: "flex", gap: "6px", alignItems: "center" }}> */}
                                {/* <i className="icons-checkbox"></i> */}
                                {report}
                                <svg style={{ width: 16, height: 16 }} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>

                                {/* </label> */}

                            </div>
                        ))}


                    </div>
                </div>
            </div>
        </div >
    );
};

export default CheckBoxes;