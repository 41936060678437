import React, { useState, useEffect } from "react";
import "../styles/rangeslidercss.css";

type RangeSlider2Props = {
    sliderMinValue: number;
    sliderMaxValue: number;
    minCurrentValue: number;
    maxCurrentValue: number;
    setDualStateValue: (value: number, isMin: boolean, category: string) => void,
    isCarat?: boolean;
    isPrice?: boolean;
    isString?: boolean;
    stringValues?: string[];
    gap?: number;
    isPercent?: boolean;
    category: string;
    stateMinCurrentValue: number;
    stateMaxCurrentValue: number;
    setStateCurrentMinVal: React.Dispatch<React.SetStateAction<number>>,
    setStateCurrentMaxVal: React.Dispatch<React.SetStateAction<number>>
};

const RangeSlider2: React.FC<RangeSlider2Props> = ({ category, isPercent, stringValues, isString, isPrice, isCarat, sliderMinValue, sliderMaxValue, minCurrentValue, maxCurrentValue, setDualStateValue, stateMinCurrentValue, stateMaxCurrentValue, setStateCurrentMinVal, setStateCurrentMaxVal }) => {


    /* const [stateMinCurrentValue, setStateCurrentMinVal] = useState(sliderMinValue);
    const [stateMaxCurrentValue, setStateCurrentMaxVal] = useState(sliderMaxValue); */

    const MID_PRICE = sliderMaxValue * 0.5

    const tempSliderMin =
        ((priceToSliderValueRounded(stateMinCurrentValue) - priceToSliderValueRounded(sliderMinValue)) / (priceToSliderValueRounded(sliderMaxValue) - priceToSliderValueRounded(sliderMinValue))) * 100;
    const tempSliderMax =
        ((priceToSliderValueRounded(stateMaxCurrentValue) - priceToSliderValueRounded(sliderMinValue)) / priceToSliderValueRounded((sliderMaxValue) - priceToSliderValueRounded(sliderMinValue))) * 100;

    const sliderMin = tempSliderMin < 0 ? 0 : tempSliderMin
    const sliderMax = tempSliderMax > 100 ? 100 : tempSliderMax

    const minGap = 0;




    function sliderValueToPrice(a: number) {

        const x = a / sliderMaxValue
        // x in [0,1]
        if (x <= 0.75) {
            // Lower segment: [0, 0.75] -> [MIN_PRICE, MID_PRICE]
            return (
                sliderMinValue + (x / 0.75) * (MID_PRICE - sliderMinValue)
            );
        } else {
            // Upper segment: (0.75, 1] -> [MID_PRICE, MAX_PRICE]
            return (
                MID_PRICE + ((x - 0.75) / 0.25) * (sliderMaxValue - MID_PRICE)
            );
        }
    }


    // "Inverse" function that tries to recover x from a rounded integer price p
    function priceToSliderValueRounded(c: number) {

        const p = c

        let x
        // Clamp p to [MIN_PRICE, MAX_PRICE]
        if (p < sliderMinValue) x = 0;
        if (p > sliderMaxValue) x = 1;

        // Lower segment if p <= MID_PRICE
        if (p <= MID_PRICE) {
            /*
              In the forward function:
                p = round(MIN_PRICE + (x/0.75)*(MID_PRICE - MIN_PRICE))
              Let a = MIN_PRICE
              Let b = (MID_PRICE - MIN_PRICE) / 0.75
              Then the unrounded value is: a + b*x.
        
              round(a + b*x) = p  implies  a + b*x ∈ [p-0.5, p+0.5)
        
              We can pick the midpoint  (p - a)/b  as our "best" x.
            */
            const a = sliderMinValue;
            const b = (MID_PRICE - sliderMinValue) / 0.75;

            x = (p - a) / b;       // midpoint
            if (x < sliderMinValue) x = 0;          // clamp
            if (x > MID_PRICE) x = 0.75;

        }
        // Upper segment
        else {
            /*
              In the forward function:
                p = round(MID_PRICE + ((x - 0.75)/0.25)*(MAX_PRICE - MID_PRICE))
              Let a = MID_PRICE
              Let b = (MAX_PRICE - MID_PRICE) / 0.25
              Then the unrounded value is: a + b*(x - 0.75).
        
              round(a + b*(x - 0.75)) = p  implies  a + b*(x - 0.75) ∈ [p-0.5, p+0.5)
        
              We can pick the midpoint again:  (p - a)/b = x' 
              So x = 0.75 + x'.
            */
            const a = MID_PRICE;
            const b = (sliderMaxValue - MID_PRICE) / 0.25;

            let xPrime = (p - a) / b;       // midpoint in the upper segment
            x = 0.75 + xPrime;
            if (x < 0.75) x = 0.75;         // clamp
            if (x > 1) x = 1;
        }
        return (x * sliderMaxValue)
    }


    const slideMin = (e: { target: { value: string; }; }) => {
        const tempValue = parseInt(e.target.value, 10);
        const value = (tempValue) >= maxCurrentValue ? (maxCurrentValue) : tempValue
        if (value >= sliderMinValue && maxCurrentValue - value >= (isString ? minGap + 1 : minGap)) {
            if (isPrice)
                setStateCurrentMinVal(sliderValueToPrice(value))
            else
                setStateCurrentMinVal(value)
        }
    };

    const slideMax = (e: { target: { value: string; }; }) => {
        const tempValue = parseInt(e.target.value, 10);
        const value = tempValue < minCurrentValue ? minCurrentValue : tempValue
        if (value <= sliderMaxValue && value - minCurrentValue >= (isString ? minGap + 1 : minGap)) {
            if (isPrice)
                setStateCurrentMaxVal(sliderValueToPrice(value))
            else
                setStateCurrentMaxVal(value)
        }
    };

    const handleMinInput = (e: { target: { value: string; }; }) => {

        const tempValue = handleInput(e.target.value) * (isCarat || isPercent ? 100 : 1);

        let value = tempValue

        setStateCurrentMinVal(value)
    };

    const handleMinInputFinalText = () => {
        let value = stateMinCurrentValue

        if (value < sliderMinValue)
            value = sliderMinValue
        else
            value = value > maxCurrentValue ? maxCurrentValue : value

        if (value >= sliderMinValue && value <= maxCurrentValue - minGap) {
            setStateCurrentMinVal(value)
            setDualStateValue(value, true, category);
        }
    }

    const handleMinInputFinal = () => {
        setDualStateValue(stateMinCurrentValue, true, category);
    }

    const handleMaxInput = (e: { target: { value: string; }; }) => {

        const tempValue = handleInput(e.target.value) * (isCarat || isPercent ? 100 : 1);

        setStateCurrentMaxVal(tempValue)
    };

    const handleMaxInputFinalText = () => {
        let value = stateMaxCurrentValue

        if (value > sliderMaxValue)
            value = sliderMaxValue
        else
            value = value < minCurrentValue ? minCurrentValue : value

        if (value <= sliderMaxValue && value >= minCurrentValue + minGap) {
            setStateCurrentMaxVal(value)
            setDualStateValue(value, false, category);
        }
    }

    const handleMaxInputFinal = () => {
        setDualStateValue(stateMaxCurrentValue, false, category);
    }

    const handleOutput = (value: number): string => {

        let isMax = false

        if (value === sliderMaxValue)
            isMax = true
        /*  console.log(sliderMaxValue)
         console.log(value) */

        if (isCarat)
            return (value / 100).toLocaleString("en-GB").replace(",", ".") + `${isMax ? "+" : ""}`

        else if (isPrice)
            return `€${value.toString()}` + `${isMax ? "+" : ""}`

        else if (isPercent)
            return `${(value / 100).toLocaleString("en-GB").replace(",", ".")} %` + `${isMax ? "+" : ""}`

        else
            return value.toString() + `${isMax ? "+" : ""}`

    }

    const handleInput = (value: string): number => {
        if (isPrice)
            value = value.replace("€", "")
        else if (isPercent)
            value = value.replace("%", "")

        if (value === "")
            return 0
        else if (isCarat || isPrice || isPercent) {
            const number = Number(value)
            if (!isNaN(number) && isFinite(number))
                return parseFloat(value)
            else
                return 0
        }
        else
            return 0
    }

    return (
        <div className="double-slider-box ">

            <div className="range-slider">
                <div style={{ left: `${sliderMin}%`, right: `${100 - sliderMax}%` }} className="slider-track"></div>
                <input
                    type="range"
                    min={sliderMinValue}
                    max={sliderMaxValue}
                    value={isPrice ? (priceToSliderValueRounded(stateMinCurrentValue)) : stateMinCurrentValue}
                    /* priceToSliderValueRounded */
                    /* isPrice ? handleOutput(sliderValueToPrice(stateMinCurrentValue)) : handleOutput(stateMinCurrentValue) */
                    onChange={slideMin}
                    onMouseUp={handleMinInputFinal}
                    className={minCurrentValue === sliderMaxValue ? "min-val active" : "min-val"}
                />
                <input
                    type="range"
                    min={sliderMinValue}
                    max={sliderMaxValue}
                    value={isPrice ? (priceToSliderValueRounded(stateMaxCurrentValue)) : stateMaxCurrentValue}
                    style={minCurrentValue === sliderMaxValue - minGap ? { display: "none" } : {}}
                    onChange={slideMax}
                    onMouseUp={handleMaxInputFinal}
                    className="max-val"
                />
            </div>
            {!isString ?
                <div className="input-box">
                    <div className="min-box">
                        <input
                            type="text"
                            value={handleOutput(Math.round(stateMinCurrentValue))}
                            onChange={handleMinInput}
                            className="min-input"
                            min={sliderMinValue}
                            max={maxCurrentValue}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleMinInputFinalText()
                                }
                            }}
                            onBlur={() => {
                                handleMinInputFinalText()
                            }}
                        />
                    </div>
                    <div className="max-box">
                        <input
                            type="text"
                            value={handleOutput(Math.round(stateMaxCurrentValue))}
                            onChange={handleMaxInput}
                            /*  onKeyDown={(e) => handleInputKeyDown(e, "max")} */
                            className="max-input"
                            min={minCurrentValue}
                            max={sliderMaxValue}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleMaxInputFinalText()
                                }
                            }}
                            onBlur={() => {
                                handleMaxInputFinalText()
                            }}
                        />
                    </div >
                </div > :
                <div style={{
                    display: "flex", height: "auto",
                    position: "relative"
                }} className="noUi-pips noUi-pips-horizontal">
                    {stringValues && stringValues.map((item, index) => (
                        <div style={{
                            transform: "translateX(0)", position: "static",
                            flex: 1
                        }} key={index} className="noUi-value noUi-value-horizontal noUi-value-sub">{item}</div>
                    ))}
                </div>}

        </div >
    );
};

export default RangeSlider2;