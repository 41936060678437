import React, { MouseEventHandler, useState } from 'react';
import {
    startWithADiamond, noRedirect, shopRedirect
} from '../../config/config'

type SettingStepProps = {
    applicationState: string,
    callback?: MouseEventHandler<HTMLElement>,
};

const SettingStep: React.FC<SettingStepProps> = ({ applicationState, callback }) => {

/*     if (applicationState !== startWithADiamond) {
        const settingItem = document.querySelector("")
    } */

    return (

        <div className="inner-inner-inner">

            <header>
                <h2>{applicationState === startWithADiamond ? "Choose Setting" : "Setting"}</h2>
            </header>

            {applicationState === startWithADiamond ?
                <div className="actions">
                    <div className="action">
                        <div style={{ cursor: "pointer" }} data-info={shopRedirect}
                            onClick={callback}>Browse Settings</div>
                    </div>
                </div> :
                <div className="actions" style={{ display: "flex", gap: 8 }}>
                    <div className="action">
                        <a style={{ cursor: "pointer" }} data-info={noRedirect} onClick={callback}>View</a>
                    </div>
                    <div className="action">
                        <a style={{ cursor: "pointer" }} data-info={shopRedirect} onClick={callback}>Change</a>
                    </div>
                </div>
            }

        </div>

    );
};

export default SettingStep