import React, { useState } from 'react';

type CompareViewSelectorProps = {
    isCompareView: boolean;
    setCompareView: React.Dispatch<React.SetStateAction<boolean>>;
    products: any[];
    compareProducts: any[];
};

const CompareViewSelector: React.FC<CompareViewSelectorProps> = ({ isCompareView, setCompareView, products, compareProducts }) => {

/* console.log("CompareViewSelector") */

    return (

        <div className="tab-filter-recommended-new" >
            <div className={!isCompareView ? "active" : ""} >
                <div onClick={() => setCompareView(false)} id="tab_diamonds_search">
                    <span>All Diamonds </span>
                    <span id="totalResult">({products.length})</span>
                    <span></span>
                </div>
            </div>

            <div className={isCompareView ? "active position-relative" : "position-relative"}  >
                <div onClick={() => setCompareView(true)} id="tab_diamonds_comparison" >

                    <span>Compare </span>
                    <span id="comparison_totalResult">({compareProducts.length})</span>
                    <span></span>
                </div>

                <div className="popover popover-green2 popover-green3  popover-stack fade bottom in" id="tooltips_compare" style={{ display: "none" }} >
                    <div aria-label="close">
                        <i className="iconfont iconfont-close2"></i>
                    </div>
                    <div className="arrow"></div>
                    <div className="popover-content fs-12">Click here to compare all of your favorite diamonds.</div>
                </div>
            </div>

        </div>
    );
};

export default CompareViewSelector